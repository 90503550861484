"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.CollectionWrapper__factory = void 0;
var ethers_1 = require("ethers");
var _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address"
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address"
            },
        ],
        name: "OwnershipTransferred",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint64",
                name: "time",
                type: "uint64"
            },
        ],
        name: "SetClose",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "uri",
                type: "string"
            },
        ],
        name: "SetContractURI",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint64",
                name: "time",
                type: "uint64"
            },
        ],
        name: "SetOpen",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "payToken",
                        type: "address"
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256"
                    },
                    {
                        internalType: "uint64",
                        name: "open",
                        type: "uint64"
                    },
                    {
                        internalType: "uint64",
                        name: "close",
                        type: "uint64"
                    },
                    {
                        internalType: "string",
                        name: "uri",
                        type: "string"
                    },
                ],
                indexed: false,
                internalType: "struct CollectionWrapper.Param",
                name: "param",
                type: "tuple"
            },
        ],
        name: "SetParam",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "contract IERC20",
                name: "payToken",
                type: "address"
            },
        ],
        name: "SetPayToken",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256"
            },
        ],
        name: "SetPrice",
        type: "event"
    },
    {
        inputs: [],
        name: "close",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "isClosed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "isLimited",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "isNeverClosed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "isOpen",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "open",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "param",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "payToken",
                type: "address"
            },
            {
                internalType: "uint256",
                name: "price",
                type: "uint256"
            },
            {
                internalType: "uint64",
                name: "open",
                type: "uint64"
            },
            {
                internalType: "uint64",
                name: "close",
                type: "uint64"
            },
            {
                internalType: "string",
                name: "uri",
                type: "string"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "payToken",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "pool",
        outputs: [
            {
                internalType: "contract MintBoxPool",
                name: "",
                type: "address"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "price",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "time",
                type: "uint64"
            },
        ],
        name: "setClose",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "contractURI_",
                type: "string"
            },
        ],
        name: "setContractURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "time",
                type: "uint64"
            },
        ],
        name: "setOpen",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "payToken",
                        type: "address"
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256"
                    },
                    {
                        internalType: "uint64",
                        name: "open",
                        type: "uint64"
                    },
                    {
                        internalType: "uint64",
                        name: "close",
                        type: "uint64"
                    },
                    {
                        internalType: "string",
                        name: "uri",
                        type: "string"
                    },
                ],
                internalType: "struct CollectionWrapper.Param",
                name: "_param",
                type: "tuple"
            },
        ],
        name: "setParam",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "payToken_",
                type: "address"
            },
        ],
        name: "setPayToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "price_",
                type: "uint256"
            },
        ],
        name: "setPrice",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [],
        name: "timestamp",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address"
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256"
            },
        ],
        name: "valueOf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
];
var CollectionWrapper__factory = /** @class */ (function () {
    function CollectionWrapper__factory() {
    }
    CollectionWrapper__factory.createInterface = function () {
        return new ethers_1.utils.Interface(_abi);
    };
    CollectionWrapper__factory.connect = function (address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    };
    CollectionWrapper__factory.abi = _abi;
    return CollectionWrapper__factory;
}());
exports.CollectionWrapper__factory = CollectionWrapper__factory;
