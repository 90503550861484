"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.ERC721SingleBoxWrapper__factory = void 0;
var ethers_1 = require("ethers");
var _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256"
            },
            {
                indexed: false,
                internalType: "address",
                name: "creator",
                type: "address"
            },
            {
                indexed: false,
                internalType: "string",
                name: "uri",
                type: "string"
            },
        ],
        name: "AddToken",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "receiver",
                type: "address"
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256"
            },
        ],
        name: "MintToken",
        type: "event"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            },
        ],
        name: "tokens",
        outputs: [
            {
                internalType: "uint256",
                name: "id",
                type: "uint256"
            },
            {
                internalType: "uint8",
                name: "status",
                type: "uint8"
            },
            {
                internalType: "address",
                name: "creator",
                type: "address"
            },
            {
                internalType: "string",
                name: "uri",
                type: "string"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
];
var _bytecode = "0x608060405234801561001057600080fd5b5061021a806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c80634f64b2be14610030575b600080fd5b61004361003e36600461011d565b61005c565b6040516100539493929190610135565b60405180910390f35b600060208190529081526040902080546001820154600283018054929360ff8316936101009093046001600160a01b03169290919061009a906101a9565b80601f01602080910402602001604051908101604052809291908181526020018280546100c6906101a9565b80156101135780601f106100e857610100808354040283529160200191610113565b820191906000526020600020905b8154815290600101906020018083116100f657829003601f168201915b5050505050905084565b60006020828403121561012e578081fd5b5035919050565b6000858252602060ff86168184015260018060a01b0385166040840152608060608401528351806080850152825b8181101561017f5785810183015185820160a001528201610163565b81811115610190578360a083870101525b50601f01601f19169290920160a0019695505050505050565b6002810460018216806101bd57607f821691505b602082108114156101de57634e487b7160e01b600052602260045260246000fd5b5091905056fea26469706673582212206712afe482be6d7e44d36fe00d7eb2d7946c28ce33eed745669b386f0181f63464736f6c63430008020033";
var ERC721SingleBoxWrapper__factory = /** @class */ (function (_super) {
    __extends(ERC721SingleBoxWrapper__factory, _super);
    function ERC721SingleBoxWrapper__factory() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _this = this;
        if (args.length === 1) {
            _this = _super.call(this, _abi, _bytecode, args[0]) || this;
        }
        else {
            _this = _super.apply(this, args) || this;
        }
        return _this;
    }
    ERC721SingleBoxWrapper__factory.prototype.deploy = function (overrides) {
        return _super.prototype.deploy.call(this, overrides || {});
    };
    ERC721SingleBoxWrapper__factory.prototype.getDeployTransaction = function (overrides) {
        return _super.prototype.getDeployTransaction.call(this, overrides || {});
    };
    ERC721SingleBoxWrapper__factory.prototype.attach = function (address) {
        return _super.prototype.attach.call(this, address);
    };
    ERC721SingleBoxWrapper__factory.prototype.connect = function (signer) {
        return _super.prototype.connect.call(this, signer);
    };
    ERC721SingleBoxWrapper__factory.createInterface = function () {
        return new ethers_1.utils.Interface(_abi);
    };
    ERC721SingleBoxWrapper__factory.connect = function (address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    };
    ERC721SingleBoxWrapper__factory.bytecode = _bytecode;
    ERC721SingleBoxWrapper__factory.abi = _abi;
    return ERC721SingleBoxWrapper__factory;
}(ethers_1.ContractFactory));
exports.ERC721SingleBoxWrapper__factory = ERC721SingleBoxWrapper__factory;
