"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.MintBoxPool__factory = exports.ICollection__factory = exports.ERC721SingleCollectionFactory__factory = exports.ERC721MultipleCollectionFactory__factory = exports.ERC1155SingleCollectionFactory__factory = exports.CollectionProxy__factory = exports.ERC721UpgradeableWrapper__factory = exports.ERC721SingleCollectionUpgradeable__factory = exports.ERC721MultiCollectionUpgradeable__factory = exports.ERC1155UpgradeableWrapper__factory = exports.ERC1155SingleCollectionUpgradeable__factory = exports.DelegatePay__factory = exports.ERC721SingleBoxWrapper__factory = exports.ERC721MultipleBoxWrapper__factory = exports.ERC1155SingleBoxWrapper__factory = exports.CollectionWrapper__factory = exports.IERC20__factory = exports.Proxy__factory = exports.ERC1967Upgrade__factory = exports.ERC1967Proxy__factory = exports.IBeacon__factory = exports.IERC165Upgradeable__factory = exports.ERC165Upgradeable__factory = exports.IERC721Upgradeable__factory = exports.IERC721ReceiverUpgradeable__factory = exports.IERC721MetadataUpgradeable__factory = exports.IERC721EnumerableUpgradeable__factory = exports.ERC721EnumerableUpgradeable__factory = exports.ERC721BurnableUpgradeable__factory = exports.ERC721Upgradeable__factory = exports.IERC1155Upgradeable__factory = exports.IERC1155ReceiverUpgradeable__factory = exports.IERC1155MetadataURIUpgradeable__factory = exports.ERC1155SupplyUpgradeable__factory = exports.ERC1155BurnableUpgradeable__factory = exports.ERC1155Upgradeable__factory = exports.OwnableUpgradeable__factory = void 0;
var OwnableUpgradeable__factory_1 = require("./factories/OwnableUpgradeable__factory");
__createBinding(exports, OwnableUpgradeable__factory_1, "OwnableUpgradeable__factory");
var ERC1155Upgradeable__factory_1 = require("./factories/ERC1155Upgradeable__factory");
__createBinding(exports, ERC1155Upgradeable__factory_1, "ERC1155Upgradeable__factory");
var ERC1155BurnableUpgradeable__factory_1 = require("./factories/ERC1155BurnableUpgradeable__factory");
__createBinding(exports, ERC1155BurnableUpgradeable__factory_1, "ERC1155BurnableUpgradeable__factory");
var ERC1155SupplyUpgradeable__factory_1 = require("./factories/ERC1155SupplyUpgradeable__factory");
__createBinding(exports, ERC1155SupplyUpgradeable__factory_1, "ERC1155SupplyUpgradeable__factory");
var IERC1155MetadataURIUpgradeable__factory_1 = require("./factories/IERC1155MetadataURIUpgradeable__factory");
__createBinding(exports, IERC1155MetadataURIUpgradeable__factory_1, "IERC1155MetadataURIUpgradeable__factory");
var IERC1155ReceiverUpgradeable__factory_1 = require("./factories/IERC1155ReceiverUpgradeable__factory");
__createBinding(exports, IERC1155ReceiverUpgradeable__factory_1, "IERC1155ReceiverUpgradeable__factory");
var IERC1155Upgradeable__factory_1 = require("./factories/IERC1155Upgradeable__factory");
__createBinding(exports, IERC1155Upgradeable__factory_1, "IERC1155Upgradeable__factory");
var ERC721Upgradeable__factory_1 = require("./factories/ERC721Upgradeable__factory");
__createBinding(exports, ERC721Upgradeable__factory_1, "ERC721Upgradeable__factory");
var ERC721BurnableUpgradeable__factory_1 = require("./factories/ERC721BurnableUpgradeable__factory");
__createBinding(exports, ERC721BurnableUpgradeable__factory_1, "ERC721BurnableUpgradeable__factory");
var ERC721EnumerableUpgradeable__factory_1 = require("./factories/ERC721EnumerableUpgradeable__factory");
__createBinding(exports, ERC721EnumerableUpgradeable__factory_1, "ERC721EnumerableUpgradeable__factory");
var IERC721EnumerableUpgradeable__factory_1 = require("./factories/IERC721EnumerableUpgradeable__factory");
__createBinding(exports, IERC721EnumerableUpgradeable__factory_1, "IERC721EnumerableUpgradeable__factory");
var IERC721MetadataUpgradeable__factory_1 = require("./factories/IERC721MetadataUpgradeable__factory");
__createBinding(exports, IERC721MetadataUpgradeable__factory_1, "IERC721MetadataUpgradeable__factory");
var IERC721ReceiverUpgradeable__factory_1 = require("./factories/IERC721ReceiverUpgradeable__factory");
__createBinding(exports, IERC721ReceiverUpgradeable__factory_1, "IERC721ReceiverUpgradeable__factory");
var IERC721Upgradeable__factory_1 = require("./factories/IERC721Upgradeable__factory");
__createBinding(exports, IERC721Upgradeable__factory_1, "IERC721Upgradeable__factory");
var ERC165Upgradeable__factory_1 = require("./factories/ERC165Upgradeable__factory");
__createBinding(exports, ERC165Upgradeable__factory_1, "ERC165Upgradeable__factory");
var IERC165Upgradeable__factory_1 = require("./factories/IERC165Upgradeable__factory");
__createBinding(exports, IERC165Upgradeable__factory_1, "IERC165Upgradeable__factory");
var IBeacon__factory_1 = require("./factories/IBeacon__factory");
__createBinding(exports, IBeacon__factory_1, "IBeacon__factory");
var ERC1967Proxy__factory_1 = require("./factories/ERC1967Proxy__factory");
__createBinding(exports, ERC1967Proxy__factory_1, "ERC1967Proxy__factory");
var ERC1967Upgrade__factory_1 = require("./factories/ERC1967Upgrade__factory");
__createBinding(exports, ERC1967Upgrade__factory_1, "ERC1967Upgrade__factory");
var Proxy__factory_1 = require("./factories/Proxy__factory");
__createBinding(exports, Proxy__factory_1, "Proxy__factory");
var IERC20__factory_1 = require("./factories/IERC20__factory");
__createBinding(exports, IERC20__factory_1, "IERC20__factory");
var CollectionWrapper__factory_1 = require("./factories/CollectionWrapper__factory");
__createBinding(exports, CollectionWrapper__factory_1, "CollectionWrapper__factory");
var ERC1155SingleBoxWrapper__factory_1 = require("./factories/ERC1155SingleBoxWrapper__factory");
__createBinding(exports, ERC1155SingleBoxWrapper__factory_1, "ERC1155SingleBoxWrapper__factory");
var ERC721MultipleBoxWrapper__factory_1 = require("./factories/ERC721MultipleBoxWrapper__factory");
__createBinding(exports, ERC721MultipleBoxWrapper__factory_1, "ERC721MultipleBoxWrapper__factory");
var ERC721SingleBoxWrapper__factory_1 = require("./factories/ERC721SingleBoxWrapper__factory");
__createBinding(exports, ERC721SingleBoxWrapper__factory_1, "ERC721SingleBoxWrapper__factory");
var DelegatePay__factory_1 = require("./factories/DelegatePay__factory");
__createBinding(exports, DelegatePay__factory_1, "DelegatePay__factory");
var ERC1155SingleCollectionUpgradeable__factory_1 = require("./factories/ERC1155SingleCollectionUpgradeable__factory");
__createBinding(exports, ERC1155SingleCollectionUpgradeable__factory_1, "ERC1155SingleCollectionUpgradeable__factory");
var ERC1155UpgradeableWrapper__factory_1 = require("./factories/ERC1155UpgradeableWrapper__factory");
__createBinding(exports, ERC1155UpgradeableWrapper__factory_1, "ERC1155UpgradeableWrapper__factory");
var ERC721MultiCollectionUpgradeable__factory_1 = require("./factories/ERC721MultiCollectionUpgradeable__factory");
__createBinding(exports, ERC721MultiCollectionUpgradeable__factory_1, "ERC721MultiCollectionUpgradeable__factory");
var ERC721SingleCollectionUpgradeable__factory_1 = require("./factories/ERC721SingleCollectionUpgradeable__factory");
__createBinding(exports, ERC721SingleCollectionUpgradeable__factory_1, "ERC721SingleCollectionUpgradeable__factory");
var ERC721UpgradeableWrapper__factory_1 = require("./factories/ERC721UpgradeableWrapper__factory");
__createBinding(exports, ERC721UpgradeableWrapper__factory_1, "ERC721UpgradeableWrapper__factory");
var CollectionProxy__factory_1 = require("./factories/CollectionProxy__factory");
__createBinding(exports, CollectionProxy__factory_1, "CollectionProxy__factory");
var ERC1155SingleCollectionFactory__factory_1 = require("./factories/ERC1155SingleCollectionFactory__factory");
__createBinding(exports, ERC1155SingleCollectionFactory__factory_1, "ERC1155SingleCollectionFactory__factory");
var ERC721MultipleCollectionFactory__factory_1 = require("./factories/ERC721MultipleCollectionFactory__factory");
__createBinding(exports, ERC721MultipleCollectionFactory__factory_1, "ERC721MultipleCollectionFactory__factory");
var ERC721SingleCollectionFactory__factory_1 = require("./factories/ERC721SingleCollectionFactory__factory");
__createBinding(exports, ERC721SingleCollectionFactory__factory_1, "ERC721SingleCollectionFactory__factory");
var ICollection__factory_1 = require("./factories/ICollection__factory");
__createBinding(exports, ICollection__factory_1, "ICollection__factory");
var MintBoxPool__factory_1 = require("./factories/MintBoxPool__factory");
__createBinding(exports, MintBoxPool__factory_1, "MintBoxPool__factory");
